var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('v-dialog',{ref:"dialogDatepicker",attrs:{"return-value":_vm.datepicker,"width":"290px","persistent":""},on:{"update:returnValue":function($event){_vm.datepicker=$event},"update:return-value":function($event){_vm.datepicker=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getRussianDate())+" ")])]}}],null,false,1450534736),model:{value:(_vm.modalDatepicker),callback:function ($$v) {_vm.modalDatepicker=$$v},expression:"modalDatepicker"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"scrollable":"","locale":"ru-ru","events":_vm.data.dates,"event-color":"green lighten-1"},model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.modalDatepicker = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialogDatepicker.save(_vm.datepicker); _vm.fetchSchedule(_vm.datepicker)}}},[_vm._v(" Выбрать ")])],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyScheduleFromPreviousWeekDay()}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.clearFormData(); _vm.modalCreate = true;}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-main',{directives:[{name:"touch",rawName:"v-touch",value:({
        left: function () { return _vm.fetchSchedule(_vm.getNextDay()); },
        right: function () { return _vm.fetchSchedule(_vm.getPrevDay()); },
      }),expression:"{\n        left: () => fetchSchedule(getNextDay()),\n        right: () => fetchSchedule(getPrevDay()),\n      }"}]},[_c('v-container',_vm._l((_vm.data.schedule),function(item,i){return _c('v-card',{key:i,staticClass:"mb-3",attrs:{"elevation":"2"}},[_c('v-card-text',{style:(_vm.tagColor(item.tag))},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('div',{staticClass:"mx-2 my-1"},[_c('strong',{staticClass:"text-h5"},[_vm._v(_vm._s(item.started_at))]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.tagName(item.tag))}}),_c('v-spacer'),_c('span',[_vm._v(_vm._s(item.student_name))])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.setFormData(item); _vm.modalEdit = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)],1)],1)}),1)],1),_c('v-dialog',{ref:"dialogEdit",attrs:{"width":"290px","persistent":""},model:{value:(_vm.modalEdit),callback:function ($$v) {_vm.modalEdit=$$v},expression:"modalEdit"}},[_c('v-card',{attrs:{"color":"grey darken-4"}},[_c('v-card-text',{staticClass:"pt-4"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Время","type":"time","solo":"","hide-details":""},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Ученик","type":"text","solo":"","hide-details":""},model:{value:(_vm.form.student_name),callback:function ($$v) {_vm.$set(_vm.form, "student_name", $$v)},expression:"form.student_name"}}),_c('v-select',{staticClass:"mt-4",attrs:{"items":[
                {text:'Обычный урок', 'value':'common'},
                {text:'Онлайн', 'value':'online'},
                {text:'Пробник', 'value':'trial'},
                {text:'Оплата', 'value':'payment'},
                {text:'Отменён', 'value':'canceled'} ],"label":"Метка урока","solo":"","hide-details":""},model:{value:(_vm.form.tag),callback:function ($$v) {_vm.$set(_vm.form, "tag", $$v)},expression:"form.tag"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteSchedule()}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.modalEdit = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.updateSchedule()}}},[_vm._v("Обновить")])],1)],1)],1),_c('v-dialog',{ref:"dialogEdit",attrs:{"width":"290px","persistent":""},model:{value:(_vm.modalCreate),callback:function ($$v) {_vm.modalCreate=$$v},expression:"modalCreate"}},[_c('v-card',{attrs:{"color":"grey darken-4"}},[_c('v-card-text',{staticClass:"pt-4"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Время","type":"time"},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Ученик","type":"text"},model:{value:(_vm.form.student_name),callback:function ($$v) {_vm.$set(_vm.form, "student_name", $$v)},expression:"form.student_name"}}),_c('v-select',{staticClass:"mt-4",attrs:{"items":[
                {text:'Обычный урок', 'value':'common'},
                {text:'Онлайн', 'value':'online'},
                {text:'Пробник', 'value':'trial'},
                {text:'Оплата', 'value':'payment'},
                {text:'Отменён', 'value':'canceled'} ],"label":"Метка урока"},model:{value:(_vm.form.tag),callback:function ($$v) {_vm.$set(_vm.form, "tag", $$v)},expression:"form.tag"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.modalCreate = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.createSchedule()}}},[_vm._v("Создать")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }