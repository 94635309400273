<template>
  <v-app v-if="data">
    <v-app-bar app color="primary" dark>

      <v-dialog
          ref="dialogDatepicker"
          v-model="modalDatepicker"
          :return-value.sync="datepicker"
          width="290px"
          persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
          >
            {{ getRussianDate() }}
          </v-btn>
        </template>
        <v-date-picker
            :first-day-of-week="1"
            v-model="datepicker"
            scrollable
            locale="ru-ru"
            :events="data.dates"
            event-color="green lighten-1"
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="grey"
              @click="modalDatepicker = false"
          >
            Отмена
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.dialogDatepicker.save(datepicker); fetchSchedule(datepicker)"
          >
            Выбрать
          </v-btn>
        </v-date-picker>
      </v-dialog>


      <v-spacer></v-spacer>

      <v-btn icon @click="copyScheduleFromPreviousWeekDay()">
        <v-icon>mdi-content-duplicate</v-icon>
      </v-btn>

      <v-btn icon @click="clearFormData(); modalCreate = true;">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

    </v-app-bar>

    <v-main
        v-touch="{
          left: () => fetchSchedule(getNextDay()),
          right: () => fetchSchedule(getPrevDay()),
        }"
    >
      <v-container>
        <v-card v-for="(item, i) in data.schedule" :key="i" elevation="2" class="mb-3">
          <v-card-text :style="tagColor(item.tag)">
            <v-row
                align="center"
                justify="space-between"
            >
              <div class="mx-2 my-1">
                <strong class="text-h5">{{ item.started_at }}</strong> <span v-html="tagName(item.tag)"></span>
                <v-spacer></v-spacer>
                <span>{{ item.student_name }}</span>
              </div>

              <v-btn icon small @click="setFormData(item); modalEdit = true;">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>

            </v-row>
          </v-card-text>
        </v-card>
      </v-container>

    </v-main>

    <v-dialog ref="dialogEdit" v-model="modalEdit" width="290px" persistent>
      <v-card color="grey darken-4">
        <v-card-text class="pt-4">
          <v-text-field
              label="Время"
              v-model="form.time"
              type="time"
              solo
              hide-details
              class="mt-4"
          ></v-text-field>
          <v-text-field
              label="Ученик"
              v-model="form.student_name"
              type="text"
              solo
              hide-details
              class="mt-4"
          ></v-text-field>
          <v-select
              :items="[
                  {text:'Обычный урок', 'value':'common'},
                  {text:'Онлайн', 'value':'online'},
                  {text:'Пробник', 'value':'trial'},
                  {text:'Оплата', 'value':'payment'},
                  {text:'Отменён', 'value':'canceled'},
              ]"
              label="Метка урока"
              v-model="form.tag"
              solo
              hide-details
              class="mt-4"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="deleteSchedule()">
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="modalEdit = false">Отмена</v-btn>
          <v-btn color="primary" text @click="updateSchedule()">Обновить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog ref="dialogEdit" v-model="modalCreate" width="290px" persistent>
      <v-card color="grey darken-4">
        <v-card-text class="pt-4">
          <v-text-field
              label="Время"
              v-model="form.time"
              type="time"
              class="mt-4"
          ></v-text-field>
          <v-text-field
              label="Ученик"
              v-model="form.student_name"
              type="text"
              class="mt-4"
          ></v-text-field>
          <v-select
              :items="[
                  {text:'Обычный урок', 'value':'common'},
                  {text:'Онлайн', 'value':'online'},
                  {text:'Пробник', 'value':'trial'},
                  {text:'Оплата', 'value':'payment'},
                  {text:'Отменён', 'value':'canceled'},
              ]"
              label="Метка урока"
              v-model="form.tag"
              class="mt-4"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="modalCreate = false">Отмена</v-btn>
          <v-btn color="primary" text @click="createSchedule()">Создать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    data: undefined,
    teacher: undefined,
    selected_day: undefined,
    datepicker: undefined,
    modalDatepicker: false,
    modalEdit: false,
    modalCreate: false,
    form: {
      id: undefined,
      time: undefined,
      student_name: undefined,
      tag: undefined,
    }
  }),

  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.teacher = urlParams.get('teacher')

    this.selected_day = urlParams.get('selected_day') ?? new Date().toISOString().substr(0, 10)
    this.datepicker = this.selected_day
    console.log(this.selected_day)

    this.fetchSchedule()
    this.getPrevDay()
    this.getNextDay(this.selected_day)
  },

  methods: {
    clearFormData() {
      this.form.time = undefined
      this.form.student_name = undefined
      this.form.tag = undefined
      this.form.id = undefined
    },

    setFormData(schedule) {
      this.form.time = schedule.started_at
      this.form.student_name = schedule.student_name
      this.form.tag = schedule.tag
      this.form.id = schedule.id
    },

    getRussianDate() {
      const day = this.selected_day ? new Date(this.selected_day) : new Date();
      const options = {weekday: 'short', month: 'short', day: 'numeric'};
      return day.toLocaleDateString("ru-RU", options)
    },

    getPrevDay() {
      const day = this.selected_day ? new Date(this.selected_day) : new Date();
      const prevDay = new Date(day);
      prevDay.setDate(day.getDate() - 1);
      return prevDay.toISOString().split('T')[0]
    },

    getNextDay() {
      const day = this.selected_day ? new Date(this.selected_day) : new Date();
      const nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);
      return nextDay.toISOString().split('T')[0]
    },

    fetchSchedule: function (day) {
      if (!this.teacher) {
        return
      }

      let self = this
      this.axios
          .get('schedule', {params: {selected_day: day}})
          .then(function (response) {
            self.data = response.data
            self.selected_day = day
          });
    },

    createSchedule: function () {
      if (!this.teacher) {
        return
      }

      let self = this
      self.form.selected_day = self.selected_day
      this.axios
          .post('schedule', self.form)
          .then(function () {
            self.clearFormData()
            self.modalCreate = false
            self.fetchSchedule(self.selected_day)
          });
    },

    updateSchedule: function () {
      if (!this.teacher) {
        return
      }

      let self = this
      self.form.selected_day = self.selected_day
      this.axios
          .post('schedule/' + self.form.id, self.form)
          .then(function () {
            self.clearFormData()
            self.modalEdit = false
            self.fetchSchedule(self.selected_day)
          });
    },

    deleteSchedule: function () {
      if (!this.teacher) {
        return
      }

      let self = this
      this.axios
          .delete('schedule/' + self.form.id)
          .then(function () {
            self.clearFormData()
            self.modalEdit = false
            self.fetchSchedule(self.selected_day)
          });
    },

    copyScheduleFromPreviousWeekDay: function () {
      if (!this.teacher) {
        return
      }

      let self = this
      this.axios
          .post('schedule/copy_prev_weekday', {selected_day: self.selected_day})
          .then(function () {
            self.clearFormData()
            self.modalEdit = false
            self.fetchSchedule(self.selected_day)
          });
    },

    tagColor: function (tag) {
      switch (tag) {
        case 'online':
          return 'border-left: 4px solid #66f !important;'

        case 'trial':
          return 'border-left: 4px solid #ffa !important;'

        case 'payment':
          return 'border-left: 4px solid #0f0 !important;'

        case 'canceled':
          return 'border-left: 4px solid #f66 !important;'

        default:
          return ''
      }
    },

    tagName: function (tag) {
      switch (tag) {
        case 'online':
          return '<small style="color: #66f">Онлайн</small>'

        case 'trial':
          return '<small style="color: #ffa">Пробник</small>'

        case 'payment':
          return '<small style="color: #0f0">Оплата</small>'

        case 'canceled':
          return '<small style="color: #f66">Отменён</small>'

        default:
          return ''
      }
    }
  }
};
</script>
