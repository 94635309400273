"use strict";

import Vue from 'vue';
import axios from "axios";

if (!process.env.VUE_APP_BASE_API_URL) {
  throw Error('Не указан базовый URL API в .env файле: VUE_APP_BASE_API_URL');
}

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
//axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'X-CSRF-Token'
//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// Full config:  https://github.com/axios/axios#request-config
//axios.defaults.baseURL = 'http://localhost:8002/api/' // process.env.baseURL || process.env.apiUrl || '';
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const teacher = urlParams.get('teacher')

    if (teacher) {
      config.headers.common['x-api-key'] = teacher
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, /*options*/) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
